.style-1 {
	padding: 0 36px;
}

.style-2 {
	padding: 0 0 36px 0px;
}

@media only screen and (max-width: 600px) {
	.style-1 {
		padding: 0;
	}
	.style-2 {
		padding: 0 0 24px 0px;
	}
}

.programe-image {
	max-height: 300px;
	width: 100%;
}
