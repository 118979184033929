.image-slider {
	padding: 8px 24px;
}
/* @media only screen and (max-width: 600px) {
	.image-slider-normal {
		display: none;
	}
	.image-slider-mobile {
		display: block;
	}
} */


/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 600px) {
  	.image-slider-normal {
		display: none;
	}
	.image-slider-mobile {
		display: block;
		padding-top: 8px;
	}
	.image-slider {
		padding: 0;
	}
}

@media screen and (min-width: 600px) {
	.image-slider-mobile {
		display: none;
	}
	
}

