.program-icon {
	width: 100%;
	max-height: 340px;
}

.program-icon2 {
	width: 72%;
	max-height: 300px;
}

.program-icon:hover {
	transform: scale(1.1) rotate(10deg);
	transition: 0.3s;
}
